import axios from "../axios/axios";
import { AxiosResponse } from "axios";
import {
  CHANNEL_MEMBER,
  GET_CODE_RESULT,
  GET_GAME_STATUS,
  HEALTH_CHECK,
  LEADERBOARD_API,
  LOGIN,
  REQUEST_HINT_API,
  REQUEST_RELOAD,
  START_GAME_API,
  SUBMIT_ANSWER_API,
} from "../constants";
import { GameLanguages, GameTypes } from "../container/Enums/Enums";

export default class NetworkManager {
  static HealthCheck(): Promise<AxiosResponse<any>> {
    return axios.get(HEALTH_CHECK);
  }

  static async GetMemberCount(code: string): Promise<AxiosResponse<any>> {
    const body = {
      channel_name: `presence-${code}`,
    };
    const data = JSON.stringify(body);
    return axios.post(CHANNEL_MEMBER, data);
  }

  static async Login(
    code: string,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    return axios.post(`${language}/${LOGIN}/${code}`);
  }

  static async GetGameStatus(
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    return axios.get(`${language}/${gameType}/${GET_GAME_STATUS}`);
  }
  static async StartGame(
    teamName: string,
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    const request = {
      teamName: teamName,
    };
    const data = JSON.stringify(request);
    return axios.post(`${language}/${gameType}/${START_GAME_API}`, data);
  }

  static SubmitAnswer(
    step: string,
    answer: string[],
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    const request = {
      gameStep: step + "",
      answer: answer,
    };
    const data = JSON.stringify(request);
    return axios.post(`${language}/${gameType}/${SUBMIT_ANSWER_API}`, data);
  }

  static GetCodeResult(
    codes: string[],
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    const request = {
      gameType: gameType,
      gameCodes: codes,
    };
    const data = JSON.stringify(request);
    return axios.post(`${language}/${GET_CODE_RESULT}`, data);
  }

  static async RequestHint(
    stepNo: string,
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    const request = {
      gameStep: stepNo + "",
    };
    const data = JSON.stringify(request);
    return axios.post(`${language}/${gameType}/${REQUEST_HINT_API}`, data);
  }

  static async RequestLeaderboard(
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    return axios.get(`${language}/${gameType}/${LEADERBOARD_API}`);
  }

  static async RequestContinuePlaying(
    gameType: string = GameTypes.TT,
    language: string = GameLanguages.DUTCH
  ): Promise<AxiosResponse<any>> {
    return axios.post(`${language}/${gameType}/${REQUEST_RELOAD}`);
  }

  // gamecode, date/time, result, hints, incorrect,
  static async SubmitResults(code: string, teamName: string, gameResult: string, totalIncorrect: number, totalUseHints: number, gameTime: number, resultInAmount: number	): Promise<AxiosResponse<any>> {
    const url =
      "https://citydiscovery.com.au/login/php/zohoforwarder.php";
  
    const request = {
      dateCompleted: new Date().toISOString().split('T')[0],
      gameCode: code,
      teamName: teamName,
      gameResult: gameResult,
      totalIncorrect: totalIncorrect,
      totalUseHints: totalUseHints,
      gameTime: gameTime,
      resultInAmount: resultInAmount
    };
    
    console.log("API Request Payload:", request); // <-- Check if teamName exists here
  
    return axios.post(url, request, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });
  }

  static async SubmitFeedback(
    selectedOption: string,
    feedbackText: string,
    code: string
  ): Promise<AxiosResponse<any>> {
    const url = "https://citydiscovery.com.au/login/php/zohovefeedbackforwarder.php";
  
    const request = {
      gameCode: code,
      selectedOption: selectedOption,
      feedbackText: feedbackText,
      dateSubmitted: new Date().toISOString().split('T')[0], // Optional timestamp
    };
  
    console.log("Submitting Feedback:", request);
  
    return axios.post(url, request, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });
  }

}