import React, { Fragment } from "react";
import FinalResult from "../assets/TTGame/Result.jpg";
import FailResult from "../assets/TTGame/FailResult.png";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import { GameLanguages } from "../container/Enums/Enums";
import { IResult } from "./TTResult";

export const getGameResultByLanguage = (language: string): IResult[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Result;
    case GameLanguages.DUTCH:
      return Result;
    default:
      return Result;
  }
};

const Result: IResult[] = [
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        CASE CLOSED
      </span>,
      <span key={2} className={"mt-2"}>
        Excellent work team, we've got him! This city is now much safer thanks
        to your help.
      </span>,
      <span key={3} className={"mt-2"}>
        If there's ever a case to solve again, we know who to call.
      </span>,
      <span key={4}>Here's a photo of the arrested suspect.</span>,
      <ImageViewer
        key={8}
        linkImage={FinalResult}
        viewerImage={FinalResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FinalResult,
    Footer: [
      <Fragment>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,

      <span key={2}></span>,
      <span className={"mt-3"} key={3}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        The bomb has exploded.
      </span>,
      <span key={4}>
        Fortunately there wasn't anyone around, but the theatre will never be
        the same again.
      </span>,
      <span key={5}>At least we tried.</span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={5}>
        Thanks for your effort. Maybe you'll be more successful when a new case
        comes up in the near future...
      </span>,
      <span className={"mt-2"} key={6}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        We didn't find his house and he got away. Hopefully he doesn't have more
        evil plans.
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        But at least we've disarmed the bomb and saved many lives!
      </span>,
      <span key={2}>
        Thanks for your hard work team. We're sure to ask you again for a future
        case if your help is needed!
      </span>,
      <span className={"mt-2"} key={6}>
        We hope you had fun! Be sure to visit our website for more fun
        experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        The squad finally managed to open the door, but it seems they were too
        late...
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [<></>],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        But at least we've disarmed the bomb and saved many lives!
      </span>,
      <span key={2}>
        Thanks for your hard work team. We're sure to ask you again for a future
        case if your help is needed!
      </span>,
      <span className={"mt-2"} key={6}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
];
