export enum ContentType {
  json = "application/json",
  multipart = "multipart/form-data",
}

export const EGameCompany = Object.freeze({
  Experios: "EXPERIOS",
  Enigma: "ENIGMA",
  FunEmpire: "FUN EMPIRE",
  EscapeRoom: "ESCAPE ROOMS FOR YOU",
  EscapeHQ: "ESCAPE HQ",
  GetMeOut: "GMO",
  GrandEscapeGame: "FUNTANIA",
  Reckitt: "RECKITT",
  None: "",
});
export const GameTypes = Object.freeze({
  TT: "TT",
  TTNL: "TTNL",
  TTJr: "TTJR",
  BBH: "BBH",
  JH: "JH",
  Island: "ISLAND",
  MAYDAY: "MAYDAY",
  BT: "BT",
});

export const GameLanguages = Object.freeze({
  ENUK: "enuk",
  DUTCH: "nl",
  PK: "pk",
  DEFAULT: "enuk",
});

export enum EMessageType {
  None,
  Success,
  Error,
  Info,
}

export enum EGameState {
  None,
  Play,
  Resume,
  Result,
}

export enum EMenuCard {
  None,
  Game,
  HowToPlay,
  LeaderBoard,
  OurGame,
}

export enum EQuestionType {
  None,
  Dropdown,
  Dropdowns,
  Input,
  InputButton,
  CheckBox,
  RadioButton,
  Component,
}

export enum EGameScreen {
  None,
  Login,
  Menu,
  Intro,
  Question,
  Result,
  HowToPlay,
  Leaderboard,
  ResultViewer,
}

export enum FriendlyShips {
  Portuguese = "Portuguese",
  French = "French",
}

export enum EnemyShips {
  English = "English",
  Dutch = "Dutch",
  Spanish = "Spanish",
}