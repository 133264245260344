import React, { Fragment } from "react";
import { EQuestionType, GameLanguages } from "../container/Enums/Enums";
import { IQuestion } from "./TTMeta";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import AudioRecorder from "../components/audioRecorder/audioRecorder";
import Step2Mapper from "../components/BT/step2Mapper/step2Mapper";
import Step7Mapper from "../components/BT/step7Mapper/step7Mapper";

import Step1 from "../assets/BlackeyeTreasure/Step1.webp";
import Step2 from "../assets/BlackeyeTreasure/Step2.jpg";
import Step3 from "../assets/BlackeyeTreasure/Step3.jpg";
import Step4A from "../assets/BlackeyeTreasure/Step4A.jpg";
import Step4B from "../assets/BlackeyeTreasure/Step4B.jpg";
import Step4C from "../assets/BlackeyeTreasure/Step4C.jpg";
import Step5A from "../assets/BlackeyeTreasure/Step5A.jpg";
import Step5B from "../assets/BlackeyeTreasure/Step5B.jpg";
import Step5C from "../assets/BlackeyeTreasure/Step5C.jpg";
import Step5D from "../assets/BlackeyeTreasure/Step5D.jpg";
import Step5E from "../assets/BlackeyeTreasure/Step5E.webp";
import Step5F from "../assets/BlackeyeTreasure/Step5F.webp";
import Step6A from "../assets/BlackeyeTreasure/Step6A.jpg";
import Step6B from "../assets/BlackeyeTreasure/Step6B.jpg";
import IngredientsPanel from "../components/BT/ingridientsPanel/ingridientsPanel";
import Step8Mapper from "../components/BT/step8Mapper/step8Mapper";
import Step8 from "../assets/BlackeyeTreasure/Step8.webp";
import Step5Slider from "../components/BT/step5Slider/step5Slider";
import Step9Mapper from "../components/BT/step9Mapper/step9Mapper";
import Step10A from "../assets/BlackeyeTreasure/Step10A.jpg";
import Step10Mapper from "../components/BT/step10Mapper/step10Mapper";
import ModelViewer from "../components/modelviewer/modelviewer";

// const Step1 = BTGameStepFolder + "Step1.png";
// const Step2 = BTGameStepFolder + "Step1.png";
// const Step3 = BTGameStepFolder + "Step1.png";
// const Step4 = BTGameStepFolder + "Step1.png";
// const Step5A = BTGameStepFolder + "Step1.png";
// const Step5B = BTGameStepFolder + "Step1.png";
// const Step6 = BTGameStepFolder + "Step1.png";
// const Step7A = BTGameStepFolder + "Step1.png";
// const Step7B = BTGameStepFolder + "Step1.png";
// const Step8A = BTGameStepFolder + "Step1.png";
// const Step8B = BTGameStepFolder + "Step1.png";
// const Step9 = BTGameStepFolder + "Step1.png";
// const Step10A = BTGameStepFolder + "Step1.png";
// const Step10B = BTGameStepFolder + "Step1.png";
// const Step11A = BTGameStepFolder + "Step1.png";
// const Step11B = BTGameStepFolder + "Step1.png";

export const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return Questions;
    default:
      return Questions;
  }
};

const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Input,
    Question:
      "Which 4 hidden numbers can be found in the letter, from top to bottom?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    Content: [Step1],
    Body: [
      <Fragment key={1}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE LETTER</span>
        <span className={"mt-2"}>
          Arrrr, what a beautiful day to be a pirate.
          <br></br>
          Today seems to be my lucky day. I've just found this letter in a bottle, washed ashore.
        </span>
        <ImageViewer
          linkImage={Step1}
          viewerImage={Step1}
          footerText={"(click to enlarge)"}
          defaultScale={0.5}
          style={{ maxWidth: "75%", minWidth: "40%", padding: "20px 40px" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          This looks like a very cryptic letter, too complicated for a simple pirate like me.<br></br>
          Can you find those numbers, hidden within parts of the words?
        </span>
      </Fragment>,
    ],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question:
      "Once Jack tells us he likes his drink and gives us what we need, press Submit Answer.",
    Options: [""],
    Body: [
      <Fragment key={2}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE TAVERN</span>
        <span className={"mt-2"}>
          Great work!
        </span>
        <span className={"mt-2"}>
          I’ve found Jack Pegleg and he is surprised when I tell him about the letter.<br></br>
          As a retired, one-legged pirate, he's happy to share whatever information he's got.<br></br>
          All he wants in return is his favourite drink, a brumbo. And it needs to be perfect.
        </span>
        <span className={"mt-3"}>
          But he’s quite far gone and doesn't know exactly what goes into his favourite drink.<br></br>
          Even worse, the bartender refuses to serve us. After some friendly threats, he hands over the ingredients and runs off.
        </span>
        <Step2Mapper />
        <span className={"mt-3"} style={{ color: "#7ED321", fontSize: "0.8em" }}>MIX THE INGREDIENTS</span>
        <span className="mt-2">I've been trying to make him his favourite drink, but all I've gotten in return is a bunch of grumbling.<br></br>
        Here's what he keeps on saying, after trying different amounts of each ingredient.</span>
        <ul className={"mt-2"}>
          <li>Not enough rum: "I'm a feared pirate, nah a little sprog lad. Add some more o' that strong stuff!"</li>
          <li>Too much rum: "Oh me, I can see pirate Fiddler's Green..."</li>
          <li>Not enough water: "You filthy landlubber, how dare ye!"</li>
          <li>Too much water: "Arrrrgh, I'll drown ye in this water."</li>
          <li>Not enough cinnamon: "More sweetwood, more!!!"</li>
          <li>Too much cinnamon: "Arrrrgh, wha' be that!?"</li>
          <li>Not enough sugar: "A bit more o' the sweetmeat, whelp!"</li>
          <li>Too much sugar: "Arrrrgh, me teeth are fallin' out!"</li>
        </ul>
        <span className="mt-2">My throat’s drier than a shipwrecked sailor. Whilst I grab myself a drink, can you figure out the right amounts?</span>
        <span className="mt-2">Start by adding a small amount of each ingredient and serve the drink.<br></br>
        Each time you serve him his drink, he'll complain about one specific ingredient, from left to right.<br></br>
        Keep adjusting the amounts based on his response and serve it until he spills the beans on the treasure!<br></br>
        Serve as many drinks as you'd like, there's no penalty for serving a drink.
        </span>
        <IngredientsPanel />
      </Fragment>,
    ],
    Content: [Step2],
  },
  {
    Id: "3",
    Type: EQuestionType.CheckBox,
    Question: "Which 5 pirates should we hire for this adventure?",
    Options: [
      "Kate",
      "Johan",
      "Jasper",
      "Tom",
      "Oscar",
      "Mike",
      "Tyron",
      "Hubert",
      "Robin",
      "Cate",
      "Herman",
      "Nick",
      "Dennis",
      "Karin",
    ],
    Body: [
      <Fragment key={3}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>TREASURE MAP</span>
        <span className={"mt-3"}>
          As pirate Pegleg is enjoying his brumbo, he tells us that the treasure can be found on an island not too far away.<br></br>
          He hands us a box with pieces of a treasure map. I'm sure that will come in handy later!
        </span>
        <span className={"mt-4"} style={{ color: "#7ED321", fontSize: "0.8em" }}>PICK A CREW</span>
        <span className={"mt-3"}>
          It’s time to find 5 pirates to join us on this adventure.<br></br>
          But not all pirates have what it takes. And some pirates don't get along.
        </span>
        <span className={"mt-3"}>
          The local job board has pirates seeking work.
        </span>
        <ImageViewer
          linkImage={Step3}
          viewerImage={Step3}
          footerText={"(click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "90%", minWidth: "25%" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          We need to consider the following:
          <ol>
            <li>We have a maximum of 5000 doubloons to spend</li>
            <li>We need at least 2 female pirates to keep the crew balanced</li>
            <li>Each pirate needs to have a unique skill</li>
            <li>Let's make sure all pirates get along</li>
          </ol>
          Grab your notepad and help me decide on which 5 pirates to hire for our adventure.<br></br>
          Start by figuring out which 2 female pirates qualify. Then eliminate pirates based on their criteria and duplicate skills.
        </span>
        
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "4",
    Type: EQuestionType.None,
    Question: "Submit your best sea shanty and hope it motivates the crew.",
    Options: [""],
    Body: [
      <Fragment key={4}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE LONG JOURNEY</span>
        <span className={"mt-3"}>
          Aye, we’ve mustered a sturdy crew, so let the adventure begin mateys!
        </span>
        <span className={"mt-3"}>
          A fine ship is scarce these days, so we’ll have to rely on my trusty old pinnace.<br></br>
          As long as we don’t cross paths with any other ships along the way...
        </span>
        <ImageViewer
          linkImage={Step4A}
          viewerImage={Step4A}
          footerText={"(click to enlarge)"}
          defaultScale={0.9}
          style={{ maxWidth: "80%", minWidth: "25%" }}
        ></ImageViewer>
        <span className={"mt-4"} style={{ color: "#7ED321", fontSize: "0.8em" }}>SEA SHANTY</span>
        <span className={"mt-2"}>
          We’ve only just set sail and the crew’s morale is already getting low.<br></br>
          But a good old sea shanty will lift the spirits no matter what happens.
        </span>
        <span className={"mt-4"}>
          Follow the lyrics and sing a sea shanty to motivate the crew!<br></br>
        </span>
        <style>
          {`
          .custom-BTStep5-Span::before {
            content: '• ';
            display: inline-block;
            text-align: center;
            width: 1em;
            }
          `}
        </style>
        <span className="custom-BTStep5-Span">
          Press the Record button when you’re ready to sing<br></br>
        </span>
        <span className="custom-BTStep5-Span">
          Press Allow to accept the permission to use your microphone in your browser<br></br>
        </span>
        <span className="custom-BTStep5-Span">
          Sing the song by following the lyrics
        </span>
        <ImageViewer
          linkImage={Step4B}
          viewerImage={Step4B}
          footerText={"(click to enlarge)"}
          defaultScale={0.5}
          style={{ maxWidth: "65%", minWidth: "30%" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          Press the Stop button when you’re done:
        </span>
        <span className={"custom-BTStep5-Span"}>
          You can listen to the result by pressing Play Back.<br></br>
        </span>
        <span className="custom-BTStep5-Span">
          You can re-record if you’re not happy with your result.<br></br>
        </span>
        <span className="custom-BTStep5-Span">
           Happy? Submit the recording to see if your voices boosted the crew’s morale.
        </span>
        <AudioRecorder />
        <span className={"mt-4"} style={{ color: "#7ED321", fontSize: "0.8em" }}>HAVING TROUBLE?</span>
        <span className={"mt-2"}>
          Make sure you don't mute your mic.<br></br>
          Didn’t allow permission to your microphone?<br></br>
          Click on the microphone icon in the top right of your browser, in the address bar, and give permission.
        </span>
        <ImageViewer
          linkImage={Step4C}
          viewerImage={Step4C}
          defaultScale={0.5}
          style={{ maxWidth: "55%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step4A, Step4B, Step4C],
  },
  {
    Id: "5",
    Type: EQuestionType.Dropdowns,
    Question: "Select the correct answer for each question.",
    Options: [""],
    DropDownsOptions: [
      ["Answer 1", "", "A", "B", "C"],
      ["Answer 2", "", "A", "B", "C"],
      ["Answer 3", "", "A", "B", "C"],
      ["Answer 4", "", "A", "B", "C"],
      ["Answer 5", "", "A", "B", "C"],
      ["Answer 6", "", "A", "B", "C"],
    ],
    Body: [
      <Fragment key={5}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>PIRATE ATTACK!</span>
        <span className={"mt-3"}>
          Looks like we’re not the only pirates looking for gold on the high sea.<br></br>
          We're being chased, outnumbered, and there's no escape!
        </span>
        <span className={"mt-3"}>
          Their captain threatens to slice our throats, unless we can prove that we are true pirates.<br></br>
          My classic pirate joke didn’t seem to work this time...<br></br>
          <span style={{ fontStyle: "italic" }}>
            Do you remember a pirate's favourite subject? Arrrrt history!
          </span>
        </span>
        <Step5Slider
          viewerImage={[
            { url: Step5A },
            { url: Step5B },
            { url: Step5C },
            { url: Step5D },
            { url: Step5E },
            { url: Step5F },
          ]}
          footerText={""}
          defaultScale={0.75}
          style={{ maxWidth: "85%", minWidth: "40%" }}
        ></Step5Slider>
        <span className={"mt-4"}>
          Let’s stay calm and talk our way out of this situation.<br></br>
          If we can answer their questions using our pirate knowledge, they might let us sail free.
        </span>
      </Fragment>,
    ],
    Content: [Step5A, Step5B, Step5C, Step5D, Step5E, Step5F],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question: "What are the coordinates (e.g. A1) of the treasure on the map?",
    Options: [""],
    Body: [
      <Fragment key={6}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>TREASURE ISLAND</span>
        <span className={"mt-3"}>
          That was a close call, but they let us go!<br></br>
          And there’s more good news, there’s land in sight!<br></br>
        </span>
        <ImageViewer
          linkImage={Step6A}
          viewerImage={Step6A}
          footerText={"(click to enlarge)"}
          defaultScale={0.9}
          style={{ maxWidth: "95%", minWidth: "40%" }}
        ></ImageViewer>
        <span className={"mt-4"} style={{ color: "#7ED321", fontSize: "0.8em" }}>MAP</span>
        <span className={"mt-2"}>
          Jack gave us a box that contains the treasure map, in lots of pieces.<br></br>
          We’ll need to put the map back together, to help us determine where to go.
        </span>
        <ImageViewer
          linkImage={"https://www.virtualescape.com.au/puzzle"}
          viewerImage={Step6B}
          footerText={"(click to open puzzle)"}
          style={{ maxWidth: "85%", minWidth: "40%" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          Use the map to find out what the location of the treasure is.
        </span>
      </Fragment>,
    ],
    Content: [
      Step6A,
      Step6B,
    ],
  },
  {
    Id: "7",
    Type: EQuestionType.Input,
    Question: "How many skulls can be found in the chamber that is lit red?",
    Options: [""],
    Body: [
      <Fragment key={7}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE CAVE</span>
        <span className={"mt-3"}>
          Ok, we've safely made it onto the island. According to the map, we need to go through the Dark Caves.<br></br>
          We’re inside, but it is seriously creepy down here.
        </span>
        <Step7Mapper />
        <span className={"mt-3"}>
          On the back of the map the following is written:<br></br>
          <span style={{ fontStyle: "italic" }}>
            Dare to enter the cave and get lost forever!?<br></br>
            Your only way out is to find the chamber that’s lit red.<br></br>
            Then play the skulls to hear the voices speak and the exit will reveal itself.
          </span>
        </span>
        <span className={"mt-4"}>
          I think we have no other option than to venture into the caves and find the red lit chamber.
        </span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "What do the skulls tell us to do next?",
    Options: [""],
    InputMaxLimit: 200,
    Body: [
      <Fragment key={8}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>SKULLS</span>
        <span className={"mt-2"}>
          It’s a good thing that pirates are fearless and never scared.<br></br>
          Although these skulls do make me nervous. You’d wonder what happened to these poor souls.
        </span>
        <Step8Mapper
          linkImage={Step8}
          viewerImage={Step8}
          footerText={"(click skulls to play audio)"}
          style={{ maxWidth: "100%", minWidth: "40%" }}
          defaultScale={1}
        ></Step8Mapper>
        <span className={"mt-3"}>
          One of the crew members tapped on the skulls and they started saying things.<br></br>
          Can you start noting down what they say and tell us what to do next?
        </span>
      </Fragment>,
    ],
    Content: [Step8],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "Find the treasure chest and click on it to solve this challenge.",
    Options: [""],
    Body: [
      <Fragment key={9}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>TIME TO START DIGGING</span>
        <span className={"mt-2"}>
          The crew made it through the creepy cave and is near the spot where the treasure should be buried.<br></br>
          But where to dig exactly? There’s so much sand here.
        </span>
        <span className={"mt-2"}>
          The crew is getting thirsty though, so we better get that spade in the ground before they run out of patience.
        </span>
        <Step9Mapper
          footerText={"(click an area to dig)"}
          style={{ maxWidth: "95%", minWidth: "95%" }}
        ></Step9Mapper>
        <span className={"mt-3"}>
          Click on an area on the map to start digging.<br></br>
          Once you’ve found the treasure chest, click on it to solve this challenge.
        </span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "10",
    Type: EQuestionType.Input,
    Question: "Sink all enemy ships and avoid hitting friendly ships",
    Options: [""],
    Body: [
      <Fragment key={10}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>SET SAIL</span>
        <span className={"mt-3"}>
          Hooray! We found the treasure and are on our way back.
        </span>
        <span className={"mt-3"}>
          But we can’t celebrate just yet. Word has spread and it’s getting busy on the high seas.<br></br>
          We're surrounded by ships, and most nations don't seem to be very fond of pirates.
        </span>
        <ImageViewer
          linkImage={Step10A}
          viewerImage={Step10A}
          footerText={"(click to open)"}
          style={{ maxWidth: "83%", minWidth: "40%" }}
        ></ImageViewer>
        <span className={"mt-5"} style={{ color: "#7ED321", fontSize: "0.8em" }}>FIRE THE CANNONS</span>
        <span className={"mt-2"}>
          Aye, we may only have a wee pinnace, with just one cannon on each side, but we won't be givin' up that easily.<br></br>
          It's time to fire some cannonballs and make our escape!
        </span>
        <Step10Mapper >
        </Step10Mapper >
        <span className={"mt-3"}>
          Look at the flag of the ship and see if it's an enemy or not.<br></br>
          When an enemy ship is in sight of our cannon, fire at will!<br></br>
          But be careful not to fire at friendly ships as that will result in a penalty.
        </span>
      </Fragment>,
    ],
    Content: [Step10A],
  },
  {
    Id: "11",
    Type: EQuestionType.Input,
    Question: "What is the code to open the treasure chest?",
    Options: [""],
    Body: [
      <Fragment key={11}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE TREASURE</span>
        <span className={"mt-3"}>
          We’ve made it back on dry land. That was one hell of an adventure.
        </span>
        <span className={"mt-3"}>
          The crew is getting impatient, we need to open up that chest quickly.<br></br>
          I can't wait to split the loot and start enjoying my pirate retirement.
        </span>
        <ModelViewer path={"BT/TreasureChest/treasurechest.glb"} exposure={1} zoomValue={50}
          poster="assests/GameFiles/BT/step11/poster1920.webp" footerText="(use your mouse to rotate and zoom in/out)"
          style={{ maxWidth: "100%", minWidth: "100%" }}
        ></ModelViewer>
        <span className={"mt-4"}>
          The chest is locked with a solid steel padlock that requires a 5-letter code.<br></br>
          There are pictures, letters and symbols on each side. That takes me back to my young pirate years! I loved solving rebus puzzles.
        </span>
        <span className={"mt-4"}>
          Rotate the chest and examine the pictures and letters. Each picture represents a word, but that’s not all.<br></br>
          Combine the word from the picture with the letters and symbols (+, -, =) below it to form a new word.<br></br>
          Once you've solved them all, you'll find the 5-letter word that will open the chest!
        </span>
      </Fragment>,
    ],
    Content: [],
  },
];
