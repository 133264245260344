import React, { Fragment } from "react";
import SuccessResult from "../assets/MayDay/success.jpg";
import FailResult from "../assets/HangoverGame/Failure.jpg";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import { GameLanguages } from "../container/Enums/Enums";
import { IResult } from "./TTResult";
// import { StringToNumber } from "../utilities/util";

export const getGameResultByLanguage = (language: string): IResult[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Result;
    case GameLanguages.DUTCH:
      return Result;
    default:
      return Result;
  }
};

export const getResultByStep = (gameStepNo: string): number => {
  // const stepNo = StringToNumber(gameStepNo);
  // if (stepNo === 13) {
  //   return 0;
  // }
  return 0;
};

const Result: IResult[] = [
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        SUCCESS
      </span>,
      <span key={2} className={"mt-2"}>
        That was one crazy adventure, but we've made it off the island alive
        with Louis and the samples!
        <br />
        I've just climbed aboard the tanker and the captain ensured me this ship
        can withstand any cyclone.
      </span>,
      <span key={3} className={"mt-2"}>
        I'll think twice before I'm ever flying an airplane again.
      </span>,
      <ImageViewer
        key={8}
        linkImage={SuccessResult}
        viewerImage={SuccessResult}
        defaultScale={1}
        footerText={""}
        style={{ maxWidth: "75%", minWidth: "70%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: SuccessResult,
    Footer: [
      <Fragment key={1}>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,

      <span key={2}></span>,
      <span className={"mt-3"} key={3}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        THE BREAK-UP
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, I missed the flight. This is a disaster.
      </span>,
      <span key={3}>
        Hannah was the love of my life, but she just texted me saying that she's
        breaking up with me.
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={4} className="mt-2">
        Oh well, I might as well stay in Vegas and go for another crazy night.
      </span>,
      <span key={5}>
        Thanks guys, you're still my best friends. Can't wait to catch up.
      </span>,
      <span key={6} className="mt-3">
        We hope you had fun. Be sure to visit our website for more fun
        experiences.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span key={1}></span>,
      <span key={2}></span>,
      <span key={3}></span>,
    ],
  },
];
