import React, { Fragment, ReactNode } from "react";
import FinalResult from "../assets/TTGame/Result.jpg";
import FailResult from "../assets/TTGame/FailResult.png";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import { GameLanguages, GameTypes } from "../container/Enums/Enums";
import * as TTJrData from "./TTJrResult";
import * as BBHData from "./BBHResult";
import * as JHData from "./JHResult";
import * as MAYDAYData from "./MayDayResult";
import * as BTData from "./BlackeyeTreasureResult";
import { StringToNumber } from "../utilities/util";

export interface IResult {
  Body: ReactNode[];
  Content: string;
  Footer: ReactNode[];
  TeamInfo: ReactNode[];
}

export const getResultByStep = (gameStepNo: string): number => {
  const stepNo = StringToNumber(gameStepNo);
  if (stepNo < 9) {
    return 1;
  } else if (stepNo >= 9 && stepNo < 12) {
    return 2;
  } else if (stepNo === 12) {
    // 11 is last step, As Step Number start from '0'
    return 3;
  } else if (stepNo === 13) {
    return 0;
  }
  return 0;
};
export const getGameResult = (type: string, language: string): IResult[] => {
  switch (type) {
    case GameTypes.TTJr:
      return TTJrData.getGameResultByLanguage(language);
    case GameTypes.BBH:
      return BBHData.getGameResultByLanguage(language);
    case GameTypes.JH:
      return JHData.getGameResultByLanguage(language);
    case GameTypes.MAYDAY:
      return MAYDAYData.getGameResultByLanguage(language);
    case GameTypes.BT:
      return BTData.getGameResultByLanguage(language);
    default:
      return getGameResultByLanguage(language);
  }
};

// export const getGameResultIndex = (
//   type: string,
//   language: string
// ): IResult[] => {
//   switch (type) {
//     case GameTypes.TTJr:
//       return TTJrData.getGameResultByLanguage(language);
//     case GameTypes.BBH:
//       return getGameResultByLanguage(language);
//     default:
//       return getGameResultByLanguage(language);
//   }
// };

const getGameResultByLanguage = (language: string): IResult[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Result;
    case GameLanguages.DUTCH:
      return ResultNL;
    default:
      return Result;
  }
};
const Result: IResult[] = [
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        CASE CLOSED
      </span>,
      <span key={2} className={"mt-2"}>
        Excellent work team, we've got him! This city is now much safer thanks
        to your help.
      </span>,
      <span key={3} className={"mt-2"}>
        If there's ever a case to solve again, we know who to call.
      </span>,
      <span key={4}>Here's a photo of the arrested suspect.</span>,
      <ImageViewer
        key={8}
        linkImage={FinalResult}
        viewerImage={FinalResult}
        footerText={""}
        defaultScale={0.65}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FinalResult,
    Footer: [
      <Fragment>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,

      <span key={2}></span>,
      <span className={"mt-3"} key={3}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        The bomb has exploded.
      </span>,
      <span key={4}>
        Fortunately there wasn't anyone around, but the theatre will never be
        the same again.
      </span>,
      <span key={5}>At least we tried.</span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={5}>
        Thanks for your effort. Maybe you'll be more successful when a new case
        comes up in the near future...
      </span>,
      <span className={"mt-2"} key={6}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        We didn't find his house and he got away. Hopefully he doesn't have more
        evil plans.
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        But at least we've disarmed the bomb and saved many lives!
      </span>,
      <span key={2}>
        Thanks for your hard work team. We're sure to ask you again for a future
        case if your help is needed!
      </span>,
      <span className={"mt-2"} key={6}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        MISSION ABORTED
      </span>,
      <span key={2} className={"mt-2"}>
        Oh no, we ran out of time and it's game over.
      </span>,
      <span key={3} className={"mt-2"}>
        The squad finally managed to open the door, but it seems they were too
        late...
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={9} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        But at least we've disarmed the bomb and saved many lives!
      </span>,
      <span key={2}>
        Thanks for your hard work team. We're sure to ask you again for a future
        case if your help is needed!
      </span>,
      <span className={"mt-2"} key={6}>
        View the global leaderboard or visit our website to discover more fun experiences.
      </span>,
    ],
  },
];

export const ResultNL: IResult[] = [
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        ZAAK GESLOTEN
      </span>,
      <span key={2} className={"mt-2"}>
        Geweldig werk team, we hebben hem! De stad is nu een stuk veiliger
        dankzij jullie hulp.
      </span>,
      <span key={3} className={"mt-2"}>
        Als er ooit nog eens een zaak moet worden opgelost, dan weten we wie we
        moeten vragen.
      </span>,
      <span key={4}>Hier is een foto van de gearresteerde.</span>,
      <ImageViewer
        key={8}
        linkImage={FinalResult}
        viewerImage={FinalResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [<></>],
    Content: FinalResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        Dat was het team. Deze zaak is nu gesloten!
      </span>,
      <span key={2}></span>,
      <span className={"mt-3"} key={3}>
        We hopen dat je het leuk vond! Bezoek onze website voor meer leuke
        spellen.
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        MISSIE GEFAALD
      </span>,
      <span key={2} className={"mt-2"}>
        O nee, de tijd is op en het is game over.
      </span>,
      <span key={3} className={"mt-2"}>
        De bom is geexplodeerd.
      </span>,
      <span key={4}>
        Gelukkig was er niemand in de buurt, maar het theater zal nooit meer
        hetzelfde zijn...
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [<></>],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={5}>
        Bedankt voor de inzet. Misschien hebben we de volgende keer meer geluk.
      </span>,
      <span className={"mt-2"} key={6}>
        We hopen dat je het leuk vond! Bezoek onze website voor meer leuke
        ervaringen
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        MISSIE GEFAALD
      </span>,
      <span key={2} className={"mt-2"}>
        O nee, de tijd is op en het is game over.
      </span>,
      <span key={3} className={"mt-2"}>
        We hebben zijn huis niet gevonden en hij is ontsnapt. Hopelijk heeft hij
        niet meer kwade plannen...
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [<></>],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        Maar gelukkig hebben we de bom wel ontschadelijk gemaakt en veel levens
        gered!
      </span>,
      <span key={2}>
        Bedankt voor jullie werk team. We weten wie we de volgende keer moeten
        vragen als er hulp nodig is!
      </span>,
      <span className={"mt-2"} key={6}>
        We hopen dat je het leuk vond! Bezoek onze website voor meer leuke
        ervaringen
      </span>,
    ],
  },
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        MISSIE GEFAALD
      </span>,
      <span key={2} className={"mt-2"}>
        O nee, de tijd is op het en het is game over.
      </span>,
      <span key={3} className={"mt-2"}>
        Het team heeft uiteindelijk de deur open gebroken, maar ze waren te
        laat...
      </span>,
      <ImageViewer
        key={8}
        linkImage={FailResult}
        viewerImage={FailResult}
        footerText={""}
        style={{ maxWidth: "45%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [<></>],
    Content: FailResult,
    Footer: [
      <span className={"mt-2"} key={1}>
        Maar gelukkig hebben we de bom ontschadelijk gemaakt en veel levens
        gered!
      </span>,
      <span key={2}>
        Bedankt voor je inzet team! We weten wie we moeten bellen als we weer
        hulp nodig hebben!
      </span>,
      <span className={"mt-2"} key={6}>
        We hopen dat je het leuk vond! Bezoek onze website voor meer leuke
        ervaringen
      </span>,
    ],
  },
];
